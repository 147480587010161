<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A leaf was electroplated with gold. The electrolysis solution contained
        <chemical-latex content="Au^3+" />
        ions. The leaf was electrolyzed with a current of
        <number-value :value="current" unit="\text{A}" />
        for a period of
        <number-value :value="time" unit="\text{s.}" />
        How much gold was deposited onto the leaf in this timeframe?
      </p>

      <calculation-input
        v-model="inputs.mass"
        prepend-text="$\text{Mass Au}:$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue';

export default {
  name: 'cpp1220Exam3Q9',
  components: {
    NumberValue,
    ChemicalLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        mass: null,
      },
    };
  },
  computed: {
    time() {
      return this.taskState.getValueBySymbol('time').content;
    },
    current() {
      return this.taskState.getValueBySymbol('current').content;
    },
  },
};
</script>
